<template>
  <div :class="{ 'special': true, 'en-font': type == 'en', 'cn-font': type == 'cn' }">
    <web-header :language="type"></web-header>
    <div class="special-wrap">
      <div class="special-wrap-header">
        <div :class="{ 'special-wrap-header-title': true, 'en-font-bold': type == 'en', 'cn-font-bold': type == 'cn' }">
          {{ subjectInfo.class_name }}
        </div>
        <div class="special-wrap-header-desc" v-if="subjectInfo.remark ">
          {{ subjectInfo.remark }}
        </div>
      </div>
      <div class="special-wrap-swiper" v-if="videoList && videoList.length > 0">
        <swiper :options="swiperOptions" class="carousel-swiper" ref="carouselSwiper">
          <swiper-slide v-for="(item, index) in videoList" :key="index">
            <a :href="createLinks(item)" class="special-wrap-swiper-img" target="_blank">
              <img :src="getSwiperImg(item)"/>
              <div class="video-mask" v-if="item.product_type && item.product_type == 3">
                <i class="iconfont icon-bofang"></i>
              </div>
            </a>
            <a :href="createLinks(item)" target="_blank" class="text-link">
              {{ item.product_name }}
            </a>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-next carousel-next" slot="button-prev"></div>
        <div class="swiper-button-prev carousel-prev" slot="button-next"></div>
      </div>
      <div class="special-wrap-list">
        <div class="special-wrap-list-cell" v-for="(item, index) in dataList" :key="index">
          <a :href="createLinks(item)" target="_blank" class="special-wrap-list-cell-title">
            <span :class="{ 'en-font-bold': type == 'en', 'cn-font-bold': type == 'cn' }">{{ item.product_name }}</span>
            <img :src="item.product_class.small_icon.img" style="max-width: 57px; margin-left: 10px; display: inline-block; vertical-align: bottom;" v-if="item.product_class && item.product_class.small_icon && item.product_class.small_icon.img" />
          </a>
          <div class="special-wrap-list-cell-desc">
            {{ removeHtmlTags(item.content) }}
          </div>
          <div :class="{ 'special-wrap-list-cell-tag': true, 'roboto-bold': type == 'en', 'cn-font-bold': type == 'cn' }">
            <span>{{ $moment(item.publish_time).format("LL") }}</span>
            <span>{{ item.author }}</span>
          </div>
        </div>
        <el-empty description="No Data" v-if="!dataList || dataList.length == 0"></el-empty>
      </div>
      <div class="special-wrap-page">
        <el-button type="danger" size="medium" round @click="dataMore" v-if="!noneMore && page.total > page.pageSize">
          {{ type == 'en' ? 'View More' : '加载更多' }}
        </el-button>
        <div class="none-more" v-else>
          {{ type == 'en' ? "There's nothing more" : "没有更多了" }}
        </div>
      </div>
    </div>
    <web-footer :language="type"></web-footer>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import { reportList, subjectList } from "@/api";
export default {
  name: "Special",
  components: {
    WebHeader,
    WebFooter,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      loading: false,
      // 音视频报告
      videoList: [],
      // 研究报告
      dataList: [],
      page: {
        // 当前页
        current: 1,
        // 每页显示的条数
        pageSize: 10,
        orderBy: "publish_time",
        sortedBy: "desc",
        // 总条数
        total: 0
      },
      noneMore: false,
      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 20,
        slidesPerGroup: 2,
        grabCursor: true,
        navigation: {
          nextEl: '.carousel-next',
          prevEl: '.carousel-prev',
        },
        breakpoints: {
          320: {  //当屏幕宽度大于等于320
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 10
          },
          768: {  //当屏幕宽度大于等于768
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 20
          },
          1280: {  //当屏幕宽度大于等于1280
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 20
          }
        }
      },
      type: "cn",
      // 专题详情
      subjectInfo: {}
    }
  },
  updated() {
    this.$nextTick(() => {
      if (this.$refs.carouselSwiper) {
        this.$refs.carouselSwiper.initSwiper();
      }
    });
  },
  created() {
    if (this.$route.params.id && this.isNumber(this.$route.params.id)) {
      if (this.$route.params.language && this.$route.params.language == "english") {
        this.type = "en";
      } else {
        this.type = "cn";
      }
      this.init(this.$route.params.id);
    } else {
      this.$router.push('/404');
    }
  },
  methods: {
    async init(subjectId) {
      this.loading = true;
      await this.getSubjectList(subjectId);
      await this.getAudioVideoList(subjectId);
      await this.getReportList(subjectId);
      this.loading = false;
    },
    // 获取音视频
    async getAudioVideoList(subjectId) {
      let param = {
        pageSize: 100,
        currentPage: 1,
        orderBy: "publish_time",
        sortedBy: "desc",
        search: `product_special_subject_id:${subjectId}|product_type:2,3|status:1`,
        searchFields: "product_special_subject_id:=|product_type:in|status:=",
      };
      let res = await reportList(param);
      if (res && res.code == 200) {
        this.videoList = res.data.list;
      }
    },
    // 获取研究报告列表
    async getReportList(subjectId) {
      let param = {
        pageSize: 100,
        currentPage: 1,
        orderBy: "publish_time",
        sortedBy: "desc",
        search: `product_special_subject_id:${subjectId}|product_type:1|status:1`,
        searchFields: "product_special_subject_id:=|product_type:in|status:=",
      };
      let res = await reportList(param);
      if (res && res.code == 200) {
        this.dataList = this.dataList.concat(res.data.list);
      }
    },
    // 获取专题列表
    async getSubjectList(subjectId) {
      let param = {
        pageSize: 50,
        currentPage: 1,
        orderBy: "sort",
        sortedBy: "desc",
        search: `is_show:true|id:${subjectId}`,
        searchFields: "is_show:=|id:=",
      };
      let res = await subjectList(param);
      if (res && res.code == 200) {
        if (res.data && res.data.list && res.data.list.length > 0) {
          this.subjectInfo = res.data.list[0];
        }
      }
    },
    // 加载更多
    dataMore() {
      if (this.page.total > this.page.current*this.page.pageSize) {
        this.page.current = this.page.current + 1;
        this.getReportList(this.subjectInfo.id);
      } else {
        this.noneMore = true;
      }
    },
    // 创建跳转链接
    createLinks(row) {
      if (this.type == 'en') {
        return `/report/english/${row.id}`;
      } else {
        return `/report/chinese/${row.id}`;
      }
    },
    // 获取音视频图片
    getSwiperImg(row) {
      let temp_url = "";
      if (row && row.img && row.img.length > 0) {
        temp_url = row.img[0]['img'];
      }
      return temp_url;
    },
    // 去除HTML标签
    removeHtmlTags(value) {
      return value ? value.replace(/<[^>]+>/g, "").replace(/&nbsp;/g, '') : "暂无简介";
    },
  }
}
</script>

<style lang="scss" scoped>
.en-font {
  font-family: Georgia;
}
.en-font-bold {
  font-family: Georgia_Bold;
}
.cn-font {
  font-family: HarmonyOS_Regular;
}
.cn-font-bold {
  font-family: HarmonyOS_Bold;
}
.roboto-light {
  font-family: Roboto_Light;
}
.roboto-bold {
  font-family: Roboto_Bold;
}
.special {
  &-wrap {
    width: 960px;
    margin: 0 auto;
    &-header {
      margin-top: 120px;
      &-title {
        font-size: 40px;
        padding-bottom: 70px;
        border-bottom: 1px solid #cccccc;
      }
      &-desc {
        padding-top: 30px;
        color: #777777;
      }
    }
    &-swiper {
      position: relative;
      margin-top: 50px;
      a {
        line-height: 0;
      }
      &-img {
        position: relative;
        display: inline-block;
        .video-mask {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(180deg, rgba(128, 128, 128, 0.5),
                      rgba(128, 128, 128, 0.5));
          i {
            color: #ffffff;
            font-size: 88px;
          }
        }
      }
      .carousel-swiper {
        width: 100%;
      }
      .carousel-next, .carousel-prev {
        width: 30px;
        height: 30px;
        color: #ed6f33;
        border: 1px solid #ed6f33;
        border-radius: 100%;
        &:hover {
          color: #ffffff;
          background-color: #ed6f33;
        }
        &:after {
          font-size: 14px;
        }
      }
      .carousel-next {
        right: -50px;
      }
      .carousel-prev {
        left: -50px;
      }
      .text-link {
        color: #231815;
        margin-top: 10px;
        line-height: inherit;
        text-decoration: none;
        &:hover {
          color: #ed6f33;
        }
      }
    }
    &-list {
      margin-top: 50px;
      &-cell {
        padding: 24px 0;
        border-top: 1px solid #e9e9e9;
        &-title {
          font-size: 22px;
          text-decoration: none;
          color: #231815;
          display: inline-block;
          span {
            cursor: pointer;
            margin-right: 10px;
            font-family: HarmonyOS_Bold;
            &:hover {
              color: #ed6f33;
            }
          }
          img {
            max-height: 23px;
            display: inline-block;
            vertical-align: bottom;
          }
        }
        &-desc {
          position: relative;
          margin: 35px 0;
          font-size: 14px;
          color: #909399;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          line-height: 30px;
          max-height: 90px;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-overflow: ellipsis;
          box-sizing: border-box;
        }
        &-tag {
          font-size: 12px;
          font-family: HarmonyOS_Bold;
          color: #747474;
          span {
            &:before {
              content: "|";
              font-family: HarmonyOS_Regular;
              margin: 0 20px;
            }
            &:first-child {
              &:before {
                content: "";
                margin: 0;
              }
            }
          }
        }
      }
    }
    &-page {
      margin: 40px auto 30px auto;
      display: table;
      .none-more {
        font-size: 12px;
        padding: 5px 15px;
        border: 1px solid #DCDFE6;
      }
      ::v-deep {
        .el-button--danger {
          background-color: #ed6f33;
          border-color: #ed6f33;
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .special {
    &-wrap {
      width: calc(100% - 60px);
      margin: 0 auto;
      &-header {
        margin-top: 40px;
        &-title {
          font-size: 28px;
          padding-bottom: 30px;
        }
      }
      &-swiper {
        .carousel-next {
          right: 10px;
        }
        .carousel-prev {
          left: 10px;
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .special {
    &-wrap {
      width: calc(100% - 60px);
      margin: 0 auto;
      &-header {
        margin-top: 40px;
        &-title {
          font-size: 28px;
          padding-bottom: 30px;
        }
      }
      &-swiper {
        .carousel-next, .carousel-prev {
          width: 26px;
          height: 26px;
          top: auto;
          bottom: -36px;
        }
        .carousel-next {
          right: 30%;
        }
        .carousel-prev {
          left: 30%;
        }
      }
    }
  }
}
</style>